import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { get, compact, lowerCase } from "lodash";
import PropTypes from "prop-types";

import Calendar from "../../../components/Calendar";
import { TextBlock, ContentBlock } from "../../../components/Blocks";
import Pages from "../../../components/Pages";
import { formatURL, makeUrl, removeEmptyKeys, removePreloader } from "../../../helpers";
import { useThemeContext } from "../../../components/Layout/ThemeContext";
import arrowLeft from "../../../images/arrow-left-calendar.svg";
import arrowRight from "../../../images/arrow-right-calendar.svg";

import "./Day.scss";
import moment from "moment";

export const query = graphql`
	query daysDataQuery($currentDay: HASURA_date!) {
		hasura {
			...Days
		}
	}
`;
// todo:fix  & remove inline styles
export default function Day({ data, pageContext }) {
	const { theme } = useThemeContext();

	const calendarData = get(data, "hasura.days[0]", {});

	const { title } = calendarData;

	const nextPrevLinks = (
		<div className={"d-flex justify-content-around pt-3 pb-2"}>
			{pageContext.prev ? (
				<Link to={formatURL(`calendar/${pageContext.prev}`)} className={"mr-2 days-arrow days-arrow__left"}>
					<div className={"arrow arrow__left"}>
						<img src={arrowLeft} />
					</div>
					<span
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						Предыдущий день
					</span>
				</Link>
			) : (
				<span />
			)}
			{pageContext.next ? (
				<Link to={formatURL(`calendar/${pageContext.next}`)} className={"mr-2 days-arrow days-arrow__right"}>
					<span
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						Следующий день
					</span>
					<div className={"arrow arrow__right"}>
						<img src={arrowRight} />
					</div>
				</Link>
			) : (
				<span />
			)}
		</div>
	);

	const url = makeUrl.day(calendarData);
	const dayName = get(calendarData, "name", "");
	const dayDate = moment(get(calendarData, "date", "")).locale("ru").format("LL");
	const weekTitle = get(calendarData, "week.title", "");
	const foodName = get(calendarData, "food.name", "");
	const holidays = get(calendarData, "days_holidays[0].holiday.title_full", "");

	const seoDataForCalendar = {
		title_full: compact([dayDate, lowerCase(dayName)]).join(", "),
		content_blocks: [
			{ body: compact([holidays, lowerCase(weekTitle), lowerCase(foodName)]).join(", ") },
		],
		main_image: {
			src:
				"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png",
		},
	};

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		calendarData && (
			<Pages entity={seoDataForCalendar} url={url}>
				<ContentBlock>
					<div className={"container calendar__page"}>
						<TextBlock className={"calendar__page_title"} title={title}>
							<Calendar data={calendarData} details={{ nextPrevLinks }} isCalendarPage />
						</TextBlock>
					</div>
				</ContentBlock>
			</Pages>
		)
	);
}

Day.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

Day.defaultProps = {
	data: {},
	pageContext: {},
};
